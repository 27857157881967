import React from 'react'
import { Link } from 'react-router-dom';
import common from '../../services/common';

const ChildCateogryDropDown = (props) => {
    const {data} = props;
  return (
    <>
        {data.exams.length > 0 && (
            <div className="subChildMenu">
                <div className="subMenuInsider">
                    {data.exams.map((exam, i3) => (
                        (
                            <Link to={`/exam-overview/${common.getTextAsSlug(exam?.name)}/${exam?._id}`} key={i3}>
                                <span className='d-flex justify-content-between'>
                                    <span>{exam.name}</span>
                                    {/* <span><i className='fa fa-chevron-right'></i></span> */}
                                </span>
                            </Link>
                        )
                    ))}
                </div>
            </div>
        )}
    </>
  )
}

export default ChildCateogryDropDown