import authAxios from "../services/authAxios";
import ROOT_URL from "./api-url";
import { reactLocalStorage } from "reactjs-localstorage";
import common from "./common";

const apiFunc = {
  postChangePassword: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/profile/changePassword`,
      loader: true,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  getProfileData: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/profile/myProfile`,
      // loader: true,
    }).catch((err) => {
       common.error(err);
    }),
  postProfileUpdate: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/profile/update`,
      loader: true,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  postUpload: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/media/upload`,
      loader: true,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  getUploadedImage: (imageId) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/media/download-file/${imageId}`,
      loader: true,
    }).catch((err) => {
       common.error(err);
    }),
  postProfileImage: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/profile/updateImage`,
      loader: true,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  refreshToken: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/profile/tokenRefresh`,
      loader: false,
    }).catch((err) => {
       common.error(err);
    }),
  getActivePackages: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/packages/activePackages`,
      loader: false,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  getExpiredPackages: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/packages/expiredPackages`,
      loader: false,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  getActiveExams: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/exams/activeExams`,
      loader: false,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
  getExpiredExams: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/exams/expiredExams`,
      loader: false,
      data: data,
    }).catch((err) => {
       common.error(err);
    }),
};

export default apiFunc;
