import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import SubCategoryDropDown from './subCategory';

const CategoryDropDown = (props) => {
    const {data} = props;
    const [itemShow, setItemShow] = useState(5)

    useEffect(()=>{
        let  h = window.innerHeight || 0;
        h = (h - 200) / 51
        setItemShow(h)

    },[])
  return (
    <>
        {data.map((post,i1) => (
            i1 < itemShow &&
            (
                <div className="subMenuWrp" key={i1}>
                    <a>
                    {/* <Link to={`exam?category=${post._id}`}> */}
                        <span className='d-flex justify-content-between'>
                            <span>{post.name}</span>

                            {post?.subCategories?.filter((d)=>d?.exams?.length > 0)?.length > 0 && (
                                <span><i className='fa fa-chevron-right'></i></span>
                            )}
                        </span>
                    {/* </Link> */}
                    </a>
                    {post?.subCategories?.filter((d)=>d?.exams?.length > 0)?.length > 0 && (
                        <SubCategoryDropDown data={post} categoryId={post._id}/>
                    )}
                </div>
            )
        ))}
        <div className='viewAllBTN'>
            <Link to='/category'><span className='d-flex justify-content-between'>All Categories  <span><i className='fa fa-chevron-right'></i></span></span></Link>
        </div>
    </>
  )
}

export default CategoryDropDown