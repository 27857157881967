import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { UserContext } from "../context/context";

export default function Sidebar() {
  let navigate = useNavigate();
  const { setLogin, reset } = useContext(UserContext);

  let location = useLocation();
  if (location.pathname == "/profile") {
    var active = "profile";
  } else if (location.pathname.includes("package")) {
    var active = "package";
  } else if (location.pathname.includes("exam")) {
    var active = "exam";
  } else if (location.pathname.includes("transaction")) {
    var active = "transaction";
  } else if (location.pathname.includes("change-password")) {
    var active = "change_password";
  } else if (location.pathname.includes("helpdesk")) {
    var active = "helpdesk";
  }

  function logout() {
    reactLocalStorage.clear();
    setLogin(false);
    navigate("/sign-in");
  }

  return (
    <>
      <div className="profileSiderbarBx">
        <ul>
          <li className={active == "profile" ? "active" : ""}>
            <Link to="/profile">My Profile</Link>
          </li>
          <li className={active == "package" ? "active" : ""}>
            <Link to="/profile/package/active/1">My Packages</Link>
          </li>
          <li className={active == "exam" ? "active" : ""}>
            <Link to="/profile/exam/active">My Exams</Link>
          </li>
          <li className={active == "transaction" ? "active" : ""}>
            <Link to="/profile/transaction">Transaction</Link>
          </li>
          <li className={active == "change_password" ? "active" : ""}>
            <Link to="/change-password">Change Password</Link>
          </li>
          <li className={active == "helpdesk" ? "active" : ""}>
            <Link to="/helpdesk">Help</Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => {
                reset();
              }}
            >
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
