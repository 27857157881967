import React from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { ErrorMessage } from '../components/formik/errorMessage';
import unAuthAxios from '../services/authAxios';
import ROOT_URL from '../services/api-url';
import common from '../services/common';
import { toast } from 'react-toastify';

const Newsletter = () => {
    const initialValues = {
        email: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string().email().required("Please enter email"),
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
          submitNewsletter(values);
        },
    });
    const submitNewsletter = (data) => {
        unAuthAxios({
            method:"POST",
            url:`${ROOT_URL}/public/newsLetter/createSubscription`,
            data:data
	    }).then((res)=>{
            toast.success(res.data.message)
            formik.resetForm()
        }).catch((error) => {
            common.error(error);
        });
    }
  return (
    <>
        <div className="newLestbox">
            <h3>Newsletter</h3>
            <form onSubmit={formik.handleSubmit}>
                <input type="email" {...formik.getFieldProps('email')} placeholder='Enter email...' />
                <button type='submit'>Subscribe</button>
            </form>
            <ErrorMessage formik={formik} name="email" />
        </div>
    </>
  )
}

export default Newsletter