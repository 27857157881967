import React from 'react'
import { Link } from 'react-router-dom';
import ChildCateogryDropDown from './childCateogry';
import common from '../../services/common';

const SubCategoryDropDown = (props) => {
    const {data, categoryId} = props;
  return (
    <>
        {data.subCategories.length > 0 && (
            <div className="childMenu">
                <div className="childMenuInsider">
                    {data.subCategories.map((subCategory,i2) => (
                        subCategory.exams.length > 0 && (
                            <div className='subChildMneuWrp' key={i2}>
                                <a>
                                {/* <Link to={`exam?category=${categoryId}&&subCategory=${subCategory._id}`} key={subCategory._id}> */}
                                    <span className='d-flex justify-content-between'>
                                        <span>{subCategory.name}</span>
                                        {subCategory.exams.length > 0 && (
                                            <span><i className='fa fa-chevron-right'></i></span>
                                        )}
                                    </span>
                                {/* </Link> */}
                                </a>
                                <ChildCateogryDropDown data={subCategory} />
                            </div>
                        )
                    ))}
                    {data.exams.map((exam,i2) => (
                        exam.length > 0 && (
                            <div className='subChildMneuWrp' key={i2}>
                                <Link to={`/exam-overview/${common.getTextAsSlug(exam?.name)}/${exam?._id}`} key={exam._id}>
                                    <span className='d-flex justify-content-between'>
                                        <span>{exam.name}</span>
                                    </span>
                                </Link>
                            </div>
                        )
                    ))}
                </div>
            </div>
        )}	
    </>
  )
}

export default SubCategoryDropDown