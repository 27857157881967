import React from 'react'

const NotFound = () => {
  return (
    <div className="no-search-result">
        <img src="/assets/images/notfound.png" className="img-fluid" />
    </div>
  )
}
const NotificationNotFound = () => {
  return (
    <div className="no-search-result notification">
        <img src="/assets/images/notfound.png" className="img-fluid" />
    </div>
  )
}

export {NotificationNotFound}
export default NotFound