import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ROOT_URL, { IMAGE_URL } from "../services/api-url";
import { UserContext } from "../context/context";
import authAxios from "../services/authAxios";
import { reactLocalStorage } from "reactjs-localstorage";
import common from "../services/common";
import "../dev.css";
import CategoryDropDown from "../components/categoryDropDown/category";
import { Dropdown } from "react-bootstrap";
import Moment from "react-moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import NotFound, { NotificationNotFound } from "../components/common/notFound";

import Sidebar from "./sidebar";

export default function Header() {
    const { isLogin, userInfo, setUserInfo, setUserCountry, cartCounter, getCartWishlist } = useContext(UserContext);
    const [posts, setPosts] = useState([]);
    const baseURL = ROOT_URL;
    const navigate = useNavigate();
    const [notificationList, setNotification] = useState(new Array());
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [searchbox, setSearchbox] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isActiveCate, setIsActiveCate] = useState(false);
    const calendarStrings = {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        nextDay: "[Tomorrow at] LT",
        lastWeek: "[last] dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "L",
    };
    useEffect(() => {
        axios
            .get(`${baseURL}/public/get/menu`)
            .then((res) => {
                setPosts(res?.data?.data);
            })
            .catch((err) => {
                common.error(err);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${baseURL}/public/getLocationByIp`)
            .then((res) => {
                setUserCountry(res?.data?.data?.country);
            })
            .catch((err) => {
                common.error(err);
            });
    }, []);

    function refreshtoken() {
        authAxios({
            method: "GET",
            url: `${ROOT_URL}/profile/tokenRefresh`,
        })
            .then((res) => {
                reactLocalStorage.set("token", res.data.token);
            })
            .catch((error) => {
                // reactLocalStorage.remove("token");
            });
    }
    const getProfile = async () => {
        await authAxios({
            method: "GET",
            url: `${ROOT_URL}/profile/myProfile`,
        })
            .then((res) => {
                setUserInfo(res.data.data);
                if (!res?.data?.data?.phone || !res?.data?.data?.isPhoneVerified) {
                    navigate("/sign-up-phone");
                }
            })
            .catch((error) => {
                common.error(error);
            });
    };

    useEffect(() => {
        let intrVal;
        if (isLogin) {
            intrVal = setInterval(() => {
                refreshtoken();
            }, 10000);
        }
        if (isLogin) {
            getProfile();
            getNotification();
            getCartWishlist();
        }
        return () => clearInterval(intrVal);
    }, [isLogin]);
    const getNotification = () => {
        let notiData = {
            page: 1,
            perPage: 20,
        };
        authAxios({
            method: "POST",
            url: `${ROOT_URL}/notifications/pagin`,
            data: notiData,
        })
            .then((res) => {
                setNotification(res.data.result);
            })
            .catch((error) => {
                common.error(error);
            });
    };

    const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

    let initialValues = {
        searchString: "",
    };
    let validationSchema = Yup.object().shape({
        searchString: Yup.string().required("Please enter exam name"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            searchSubmit(values);
        },
    });

    const searchSubmit = (values) => {
        setSearchbox(false);
        navigate(`/exam?searchString=${values.searchString}`);
    };

    return (
        <>
            <header className="headerMainWrp">
                <div className={`heaerMainwrp ${searchbox ? "active" : ""}`}>
                    <div className="menuWrarpr">
                        <nav className="navbar navbar-expand-md navbar-light menubarWrp">
                            <Link to="/" className="navbar-brand">
                                <img src="/assets/images/logo.png" />
                            </Link>

                            <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                <ul className="navbar-nav me-auto header_nav">
                                    <li className="nav-item listMenuswrp">
                                        <div className="menuDropdown">
                                            <a className="dropBtn">
                                                <div className="category-header">
                                                    <div>Category</div>
                                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                            <div className={`MegaMenuDropWrap ${isActiveCate ? "cateActive" : ""}`}>
                                                <div className="menuInsideWrp">
                                                    <div className={`Mobile_menuClose ${isActiveCate ? "" : ""}`}>
                                                        <i
                                                            className="fa-solid fa-xmark"
                                                            onClick={() => {
                                                                setIsActiveCate((current) => !current);
                                                            }}
                                                        ></i>
                                                    </div>

                                                    <CategoryDropDown data={posts} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="right-nav">
                                    <li className="nav-item">
                                        <a onClick={() => setSearchbox(true)}>
                                            <img src="/assets/images/search-icon.png" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/cart">
                                            <img src="/assets/images/shopping-cart.png" className="img-fluid" />
                                            {cartCounter?.cartList ? <span className="counter">{cartCounter?.cartList}</span> : ""}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/wishlist">
                                            <img src="/assets/images/awesome-heart.png" className="img-fluid" />
                                            {cartCounter?.wishList ? <span className="counter">{cartCounter?.wishList}</span> : ""}
                                        </NavLink>
                                    </li>

                                    {isLogin ? (
                                        <>
                                            <li className="nav-item dropdownWrp">
                                                <Dropdown show={dropdownIsOpen} onToggle={toggleDropdown}>
                                                    <Dropdown.Toggle variant="thm" id="dropdown-basic">
                                                        <img src="/assets/images/notification-icon.png" />
                                                        {/* <i className={"far fa-bell"}></i> */}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <>
                                                            <div className="notification_popup_inner">
                                                                <div className="notifyInside scroller">
                                                                    {notificationList?.length > 0 ? (
                                                                        notificationList?.map((data, key) => (
                                                                            <div className="notifyWrappr  p-3" key={key}>
                                                                                <div className="d-flex flex-wrap justify-content-between notifyHead">
                                                                                    <div className="notifyHeadinNamge">
                                                                                        <h4>{data.title}</h4>
                                                                                    </div>
                                                                                    <div className="notifyTimer">
                                                                                        <Moment calendar={calendarStrings}>{data?.createdAt}</Moment>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="notifyDescrp">
                                                                                    <p>{data?.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <NotificationNotFound />
                                                                    )}
                                                                </div>
                                                                <Link to="/notifications">
                                                                    <span onClick={() => toggleDropdown()} className="btn cus_btn custom01 d-block">
                                                                        {" "}
                                                                        View All{" "}
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="nav-item userActive user_name">
                                                <NavLink to="/profile" className="nav-btn">
                                                    <span className="profileicon">
                                                        <i className="fa fa-user"></i>
                                                    </span>
                                                    {userInfo?.name}
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/sign-in" className="nav-btn">
                                                    Sign In
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/sign-up" className="nav-btn">
                                                    Sign Up
                                                </NavLink>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {isLogin && (
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" onClick={() => setIsActive((current) => !current)}>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                )}
                            </div>
                        </nav>
                    </div>
                    <div className="headerSearwrp">
                        <div className="container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="formMainwrp">
                                    <div className="searciocn">
                                        <i className="fa fa-search" />
                                    </div>
                                    <div className="searchInput">
                                        <input type="text" {...formik.getFieldProps("searchString")} placeholder="Search exams..." />
                                    </div>
                                    <div className="closeicon" onClick={() => setSearchbox(false)}>
                                        <i className="fa fa-times" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {isLogin && (
                    <div className={`Mobile_menu ${isActive ? "active" : ""}`}>
                        <div className={`Mobile_menuClose ${isActive ? "" : ""}`}>
                            <i className="fa-solid fa-xmark" onClick={() => setIsActive((current) => !current)}></i>
                        </div>
                        <div className="profile_Img">
                            <img src={userInfo?.image?.path ? IMAGE_URL + userInfo?.image?.path : "/assets/images/usrdammy.png"} className="img-fluid" />

                            <h6>{userInfo?.name}</h6>
                            <div className="form-group">
                                {/* <button
                                type="button"
                                className="btn-thm-01"
                                onClick={() => {
                                    setIsActive(false);
                                    setIsActiveCate(true);
                                }}
                            >
                                <i className="fa-solid fa-angle-left"></i> Category
                            </button> */}
                                <Link
                                    to="/category"
                                    type="button"
                                    className="btn btn-thm-01 d-flex align-items-center justify-content-between gap-3"
                                    onClick={() => {
                                        setIsActive(false);
                                    }}
                                >
                                     Category <i className="fa-solid fa-angle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="profile_slide">
                            <Sidebar />
                        </div>
                    </div>
                )}
            </header>
        </>
    );
}
