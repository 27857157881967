// let ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/c'
let DomainURL =''
let ROOT_URL =''
let IMAGE_URL =''

console.log(process.env.REACT_APP_SERVER)
if(process.env.REACT_APP_SERVER?.trim() !== 'production'){
    DomainURL ='https://api.prepitome.com'
    ROOT_URL ='https://api.prepitome.com/api/v1/u'
    IMAGE_URL ='https://d2fopqibxip6jd.cloudfront.net/'
}else{
    DomainURL ='https://api.prepitome.com'
    ROOT_URL = "https://api.prepitome.com/api/v1/u";
    IMAGE_URL ='https://d2fopqibxip6jd.cloudfront.net/'
}
// DomainURL ='http://staging.alphonic.net.in:6400'
// ROOT_URL = "http://staging.alphonic.net.in:6400/api/v1/u";
export {IMAGE_URL, DomainURL}
export default ROOT_URL;