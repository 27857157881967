import logo from './logo.svg';
import './App.css';
import { UserContext, UserProvider } from './context/context';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './layout/layout';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import ROOT_URL, { DomainURL } from './services/api-url';

function App() {
  const [isLogin, setLogin] = useState(false);

  const reset = () =>{
    setLogin(false);
  }


  return (
    <BrowserRouter>
      <UserProvider>
        <Layout/>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
